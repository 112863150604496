import { ref, computed, watch } from "vue";
import { onClickOutside, useEventListener } from "@vueuse/core";

export function useDropdownNavigation(
  buttonNode,
  menuitemNodes,
  domNode,
  onSelectMenuitem,
  isSelect
) {
  // Setup

  const showDropdown = ref(false);

  const selectedMenuitem = ref(null);

  const firstChars = computed(() => {
    return menuitemNodes.value.map((node) => {
      const textNode = node.labels?.[0] || node;
      const textContent = textNode.textContent.trim()[0] || "";
      return textContent.toLowerCase();
    });
  });

  const firstMenuitem = computed(() => {
    return menuitemNodes.value[0];
  });

  const lastMenuitem = computed(() => {
    const maxIndex = Math.max(0, menuitemNodes.value.length - 1);
    return menuitemNodes.value[maxIndex];
  });

  // Dropdown

  const openDropdown = () => (showDropdown.value = true);
  const closeDropdown = () => (showDropdown.value = false);
  const toggleDropdown = () => (showDropdown.value = !showDropdown.value);

  const selectMenuitem = async (item) => {
    const index = menuitemNodes.value.indexOf(item);
    const value = await onSelectMenuitem(item, index);
    if (value !== false) {
      selectedMenuitem.value = item;
      closeDropdown();
    }
  };

  // Focus

  const setFocusToMenuitem = (newMenuitem) => {
    newMenuitem.focus();
  };

  const setFocusToFirstMenuitem = () => {
    setFocusToMenuitem(firstMenuitem.value);
  };

  const setFocusToLastMenuitem = () => {
    setFocusToMenuitem(lastMenuitem.value);
  };

  const setFocusToPreviousMenuitem = (currentMenuitem) => {
    const index = menuitemNodes.value.indexOf(currentMenuitem);
    const newMenuitem =
      index > 0 ? menuitemNodes.value[index - 1] : lastMenuitem.value;

    setFocusToMenuitem(newMenuitem);

    return newMenuitem;
  };

  const setFocusToNextMenuitem = (currentMenuitem) => {
    const index = menuitemNodes.value.indexOf(currentMenuitem);
    const newMenuitem =
      index < menuitemNodes.value.length - 1
        ? menuitemNodes.value[index + 1]
        : firstMenuitem.value;

    setFocusToMenuitem(newMenuitem);

    return newMenuitem;
  };

  const setFocusByFirstCharacter = (currentMenuitem, char) => {
    let start, index;

    if (char.length > 1) {
      return;
    }

    char = char.toLowerCase();

    // Get start index for search based on position of currentItem
    start = menuitemNodes.value.indexOf(currentMenuitem) + 1;
    if (start >= menuitemNodes.value.length) {
      start = 0;
    }

    // Check remaining slots in the menu
    index = firstChars.value.indexOf(char, start);

    // If not found in remaining slots, check from beginning
    if (index === -1) {
      index = firstChars.value.indexOf(char, 0);
    }

    // If match was found...
    if (index > -1) {
      setFocusToMenuitem(menuitemNodes.value[index]);
    }
  };

  // Menu event handlers

  const onButtonFocusin = () => {
    !isSelect && openDropdown();
  };

  const onButtonKeydown = (event) => {
    var key = event.key,
      flag = false;

    switch (key) {
      case " ":
        if (isSelect) {
          toggleDropdown();
        }
        break;

      case "Enter":
        if (isSelect) {
          toggleDropdown();
        } else {
          openDropdown();
          setFocusToFirstMenuitem();
          flag = true;
        }
        break;

      case "ArrowDown":
      case "Down":
        openDropdown();
        setFocusToFirstMenuitem();
        flag = true;
        break;

      case "Esc":
      case "Escape":
        closeDropdown();
        buttonNode.value.focus();
        flag = true;
        break;

      case "Up":
      case "ArrowUp":
        // openPopup();
        openDropdown();
        setFocusToLastMenuitem();
        flag = true;
        break;

      case "Tab":
        closeDropdown();
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  const onButtonMousedown = () => {
    if (isSelect) {
      toggleDropdown();
    } else {
      openDropdown();
    }
  };

  const onMenuitemKeydown = (event) => {
    var tgt = event.currentTarget,
      key = event.key,
      flag = false;

    if (event.ctrlKey || event.altKey || event.metaKey) {
      return;
    }

    if (event.shiftKey) {
      if (isPrintableCharacter(key)) {
        setFocusByFirstCharacter(tgt, key);
        flag = true;
      }

      if (event.key === "Tab") {
        buttonNode.value.focus();
        closeDropdown();
        flag = true;
      }
    } else {
      switch (key) {
        case "Enter":
          selectMenuitem(event.target);
          flag = true;
          break;

        case "Esc":
        case "Escape":
          closeDropdown();
          buttonNode.value.focus();
          flag = true;
          break;

        case "Up":
        case "ArrowUp":
          setFocusToPreviousMenuitem(tgt);
          flag = true;
          break;

        case "ArrowDown":
        case "Down":
          setFocusToNextMenuitem(tgt);
          flag = true;
          break;

        case "Home":
        case "PageUp":
          setFocusToFirstMenuitem();
          flag = true;
          break;

        case "End":
        case "PageDown":
          setFocusToLastMenuitem();
          flag = true;
          break;

        case "Tab":
          closeDropdown();
          break;

        default:
          if (isPrintableCharacter(key)) {
            setFocusByFirstCharacter(tgt, key);
            flag = true;
          }
          break;
      }
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  const onMenuitemMouseover = (event) => {
    event.currentTarget.focus();
  };

  const onMenuitemClick = (event) => selectMenuitem(event.currentTarget);

  watch(
    menuitemNodes,
    () => {
      useEventListener(buttonNode, "keydown", onButtonKeydown);
      useEventListener(buttonNode, "mousedown", onButtonMousedown);
      useEventListener(buttonNode, "focusin", onButtonFocusin);

      menuitemNodes.value?.forEach((node) => {
        const label = node.labels?.[0];
        node.tabIndex = 0;
        useEventListener(label || node, "mouseover", onMenuitemMouseover);
        useEventListener(node, "keydown", onMenuitemKeydown);
        useEventListener(node, "click", onMenuitemClick);
      });
    },
    { immediate: true, flush: "post" }
  );

  onClickOutside(domNode, () => {
    closeDropdown();
  });

  return {
    showDropdown,
    selectedMenuitem,
  };
}

function isPrintableCharacter(str) {
  return str.length === 1 && str.match(/\S/);
}
