<template>
  <div>
    <label class="custom-checkbox">
      <input
        :id="id"
        v-model="inputValue"
        :name="name"
        type="checkbox"
        :aria-invalid="!!errorMessage"
        :aria-describedby="`${name}-error`"
        :checked="modelValue"
      />
      <span v-html="label" />
    </label>
    <p
      v-if="!!errorMessage"
      :id="`${name}-error`"
      role="alert"
      class="form-error"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script setup>
import { useField } from "vee-validate";

const props = defineProps({
  label: {
    type: String,
    required: false,
    default: "",
  },
  id: {
    type: String,
    required: false,
    default: "",
  },
  name: {
    type: String,
    required: false,
    default: "",
  },
  rules: {
    type: [String, Object],
    required: false,
    default: "",
  },
  required: {
    type: Boolean,
    required: false,
  },
  modelValue: {
    type: Boolean,
    required: true,
    default: () => {
      return false;
    },
  },
  isFromServiceLocator: {
    type: Boolean,
    required: false,
    default: () => {
      return false;
    },
  },
});

const rules = computed(() => {
  if (props.rules) return props.rules;
  if (props.required) return "required";
});

const { value: inputValue, errorMessage } = useField(props.name, rules);

const emit = defineEmits(["update:modelValue"]);
</script>

<style lang="scss" scoped>
.custom-checkbox {
  align-items: start;
  input[type="checkbox"] {
    margin-top: 0.125rem;
  }
  :deep(input[name="privacy"]),
  :deep(input[name="dataPrivacy"]) {
    & + span p {
      display: inline-block;
    }
  }
  & span {
    display: flex;
    & p {
      padding: 0 2px 0 0;
    }
  }
}
</style>
